import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Hizmetlerimiz | Summit Haven Ski Retreat Hizmetleri
			</title>
			<meta name={"description"} content={"Her Kar Yolu Olağanüstü Hizmete Çıkar - Summit Haven'ı Keşfedin"} />
			<meta property={"og:title"} content={"Hizmetlerimiz | Summit Haven Ski Retreat Hizmetleri"} />
			<meta property={"og:description"} content={"Her Kar Yolu Olağanüstü Hizmete Çıkar - Summit Haven'ı Keşfedin"} />
			<meta property={"og:image"} content={"https://laxigenturra.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://laxigenturra.com/img/6347621.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://laxigenturra.com/img/6347621.png"} />
			<meta name={"msapplication-TileImage"} content={"https://laxigenturra.com/img/6347621.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				color="--primary"
			>
				Hizmetlerimiz
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Summit Haven Ski Retreat'te sunduğumuz bir dizi hizmet, her misafirimizin dağ eğlencesi ve sporunun zirvesini deneyimlemesini sağlamak için tasarlanmıştır. İşte zirvede bize katıldığınızda sizi nelerin beklediğine dair bir enstantane.
			</Text>
		</Section>
		<Section padding="0px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Hizmetlerimiz
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
				margin="0px 0px 30px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
							Yamaçlar
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							• Çeşitli Parkurlar: İster acemi ister uzman olun, pist seçeneklerimiz beceri seviyenize uygun bir mücadele sunar.
							<br />
							<br />
							• Kar Kalitesi: Düzenli tımar ve kar yapma sistemleri, kayak ve snowboard için ideal koşulları sağlar.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
							Ekipman ve Kiralama
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							• Son Teknoloji Ekipmanlar: Deneyimli personelimiz tarafından mükemmelliğe ayarlanmış en yeni kayak ve snowboard ekipmanlarını kiralayın.
							<br />
							<br />
							• Donanım Hizmetleri: Her yaşa ve bedene hitap eden kışlık kıyafet ve güvenlik ekipmanı çeşitlerimizle kendinizi hazırlayın.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
							Talimatlar
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							• Özel Dersler: Tekniği ve güveni geliştirmek için sertifikalı eğitmenlerden bire bir koçluk.
							<br />
							<br />
							• Grup Dersleri: Akranlarınızla birlikte öğrenin ve eğlenin - güvenlik ve beceri ilerlemesine odaklanan çeşitli beceri seviyeleri için sınıflar mevcuttur.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
							Olanaklar
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							• Dağ Locaları: Dağların panoramik manzarasını sunan konforlu localarımızda dinlenin ve yakıt ikmali yapın.
							<br />
							<br />
							• Yemek Seçenekleri: Yerel ve uluslararası mutfaklardan yemekler sunan, gündelikten gurmeye kadar çeşitli yemek deneyimleri.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
							Aile ve Çocuklar
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							• Çocuk Programları: Çocuklar için gözetimli aktiviteler ve kayak okulu, karın tadını yetişkinler kadar çıkarmalarını sağlar.
							<br />
							<br />
							• Aile Hizmetleri: Çocuk bakımından aile dostu etkinliklere kadar ailelere yönelik olanaklar ve hizmetler.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
							Güvenlik ve Yardım
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							• Dağ Devriyesi: Yardım ve acil durumlar için yüksek eğitimli kayak devriyesi mevcuttur.
							<br />
							<br />
							• İlk Yardım İstasyonları: Tüm misafirlerin güvenliğini ve refahını sağlamak için ilk yardım ihtiyaçlarını karşılayacak şekilde donatılmıştır.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://laxigenturra.com/img/7.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Hizmetlerimize kapsamlı bir genel bakış
				</Text>
				<Text font="--base">
					Bizi Ziyaret Edin: Summit Haven farkını bizzat yaşayın.
					<br />
					Bize Ulaşın: Özel ekibimiz sorularınızı yanıtlamaya ve mükemmel dağ kaçamağınızı planlamanıza yardımcı olmaya hazır.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});